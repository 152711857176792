<template>
    <div class="table-responsive mb-0 pb-0">
      <table
      class="table table-head-custom table-vertical-center table-head-bg"
      >
        <thead>
          <tr class="text-uppercase">
            <th class="pl-6">
              <span @click="orderByDeviceName" class="cursor-pointer">
                <span
                  class="pt-2"
                >
                  {{ $t('COMPANIES.DEVICE_NAME') }}
                </span>
                <span
                  class="svg-icon svg-icon-sm pl-2"
                >
                  <inline-svg
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                </span>
              </span>
            </th>
            <th class="text-right pl-6">IMEI</th>
            <th class="text-right">OZNAKA</th>
            <th class="text-right">{{ $t('GENERAL.LOCATION') }}</th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
              {{ $t('GENERAL.LESSOR') }}
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
              NETO PRIHOD
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
             NETO NAJAM
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
             NETO TROSAK NAGRADE
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
             EBITDA APARATA/LOKACIJE
            </th> 
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ebitdaData, key) in data" :key="key + '_' + key">
            <td class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{ebitdaData.deviceName}}</td>
            <td class="text-center">{{ebitdaData.imei}}</td>
            <td class="text-center">{{ebitdaData.tag}}</td>
            <td class="text-center">{{ebitdaData.location}}</td>
            <td class="text-center">{{ebitdaData.lessor}}</td>
            <td class="text-center">{{ebitdaData.netto}}</td>
            <td class="text-center">{{ebitdaData.nettoLease}}</td>
            <td class="text-center">/</td>
            <td class="text-center">{{ebitdaData.deviceLocationEbitda}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'EbitdaTable',
    props: {
      traffic: { type: Array },
    },
    computed: {
      ...mapGetters(['currentUserCompanyOib', 'currentUserCompanyModule']),
    },
    data() {
      return {
        data: [],
        deviceNameOrder: { query: 'asc', isActive: false },
        coinOrder: { query: 'asc', isActive: false },
        smsOrder: { query: 'asc', isActive: false },
        cardOrder: { query: 'asc', isActive: false },
        cSOrder: { query: 'asc', isActive: false },
        loyaltyOrder: { query: 'asc', isActive: false },
        totalOrder: { query: 'asc', isActive: false },
        isGenerated: false,
      }
    },
    mounted() {
        console.log("TRAFFIC", this.traffic)
      this.traffic.forEach((deviceEbitda) => {
        console.log("EBITDADATA", deviceEbitda)

        const row = this.data.findIndex(
          (device) => device.imei === deviceEbitda.imei
        )
        console.log("RO", row)
        const col = this.data[row]

        console.log("col", col)
  
        if (col === undefined) {
          this.data.push({
            deviceName: deviceEbitda.deviceName,
            imei: deviceEbitda.imei,
            tag: deviceEbitda.tag,
            location: deviceEbitda.location,
            lessor: deviceEbitda.lessor,
            netto: deviceEbitda.netto,
            nettoLease: deviceEbitda.nettoLease,
            deviceLocationEbitda: deviceEbitda.deviceLocationEbitda
          })

          console.log("thi", this.data)
        } else {
        //   this.data[row].netCoin += netCoin
        //   this.data[row].coin += coin
        //   this.data[row].netSms += netSms
        //   this.data[row].sms += sms
        //   this.data[row].netCard += netCard
        //   this.data[row].card += card
        //   this.data[row].net += net
        //   this.data[row].pdv += pdv
        //   this.data[row].amount += amount
        //   this.data[row].loyalty += loyalty
        //   this.data[row].total += total
        }
      })
  
      setTimeout(() => {
        this.isGenerated = true
      }, 1000)
    },
    methods: {
      orderByDeviceName() {
        this.clearActiveFilters()
        this.deviceNameOrder.isActive = true
  
        if (this.deviceNameOrder.query === 'asc') {
          this.deviceNameOrder.query = 'desc'
          this.data.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })
        } else {
          this.deviceNameOrder.query = 'asc'
          this.data.sort((a, b) => {
            if (a.name > b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })
        }
      },
      orderByCoin() {
        this.clearActiveFilters()
        this.coinOrder.isActive = true
  
        if (this.coinOrder.query === 'asc') {
          this.coinOrder.query = 'desc'
          this.data.sort((a, b) => a.coin - b.coin)
        } else {
          this.coinOrder.query = 'asc'
          this.data.sort((a, b) => b.coin - a.coin)
        }
      },
      orderBySms() {
        this.clearActiveFilters()
        this.smsOrder.isActive = true
  
        if (this.smsOrder.query === 'asc') {
          this.smsOrder.query = 'desc'
          this.data.sort((a, b) => a.sms - b.sms)
        } else {
          this.smsOrder.query = 'asc'
          this.data.sort((a, b) => b.sms - a.sms)
        }
      },
      prderByCard() {
        this.clearActiveFilters()
        this.cardOrder.isActive = true
  
        if (this.cardOrder.query === 'asc') {
          this.cardOrder.query = 'desc'
          this.data.sort((a, b) => a.card - b.card)
        } else {
          this.cardOrder.query = 'asc'
          this.data.sort((a, b) => b.card - a.card)
        }
      },
      orderByCs() {
        this.clearActiveFilters()
        this.cSOrder.isActive = true
  
        if (this.cSOrder.query === 'asc') {
          this.cSOrder.query = 'desc'
          this.data.sort((a, b) => a.amount - b.amount)
        } else {
          this.cSOrder.query = 'asc'
          this.data.sort((a, b) => b.amount - a.amount)
        }
      },
      orderByLoyalty() {
        this.clearActiveFilters()
        this.loyaltyOrder.isActive = true
  
        if (this.loyaltyOrder.query === 'asc') {
          this.loyaltyOrder.query = 'desc'
          this.data.sort((a, b) => a.loyalty - b.loyalty)
        } else {
          this.loyaltyOrder.query = 'asc'
          this.data.sort((a, b) => b.loyalty - a.loyalty)
        }
      },
      orderByTotal() {
        this.clearActiveFilters()
        this.totalOrder.isActive = true
  
        if (this.totalOrder.query === 'asc') {
          this.totalOrder.query = 'desc'
          this.data.sort((a, b) => a.total - b.total)
        } else {
          this.totalOrder.query = 'asc'
          this.data.sort((a, b) => b.total - a.total)
        }
      },
      clearActiveFilters() {
        this.deviceNameOrder.isActive = false
        this.coinOrder.isActive = false
        this.smsOrder.isActive = false
        this.cSOrder.isActive = false
        this.loyaltyOrder.isActive = false
        this.totalOrder.isActive = false
      },
    },
  }
  </script>
  
